import React from 'react'

function FeatureDisplay({ logo, title, description }) {
  return (
    <div className='feature'>
        <div className="ft-header">
            <img src={logo} alt="icon" height="70px"></img>
            <h1>{title}</h1>
        </div>
        <p>
            {description}
        </p>
    </div>
  )
}

export default FeatureDisplay