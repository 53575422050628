import React, { useState } from "react";
import PreLoader from "./PreLoader";
import Footer from "../components/Footer";
import Preview from "../components/Preview";
import {RemoveScrollBar} from 'react-remove-scroll-bar';

<RemoveScrollBar />

function Projects() {
    return (
        <div className="projects-main">
            <PreLoader loadTime={0.6} delay={1.2} title="PROJECTS"/>
            <h1 className="prm-h1">Projects</h1>
            <div className="preview-list">
            <Preview animationDelay={0.1} imageURL={require("../assets/saavn.png")} title="JioSaavn Player" github="https://github.com/jashvakharia/saavn-web-ui" link="https://mpstme-song-player-jash.onrender.com/" description="It's an all-in-one ad-free online music player where you can: Stream your favorite songs without interruptions Download tracks for free with just a click 🎧 Enjoy a seamless and clean interface I created this platform to make music accessible to everyone, without the clutter of ads or subscriptions. If you're looking for a smooth and hassle-free music experience, give it a try!"/>
                <Preview animationDelay={0.2} imageURL={require("../assets/beginner.png")} title="Beginner Course" github="https://github.com/jashvakharia/beginner-course" link="https://jashvakharia.in/beginner/" description="I created a beginner-friendly resource designed to help aspiring developers embark on their programming journey. 🌟

𝘞𝘩𝘢𝘵’𝘴 𝘐𝘯𝘴𝘪𝘥𝘦?

𝗚𝗲𝘁𝘁𝗶𝗻𝗴 𝗦𝘁𝗮𝗿𝘁𝗲𝗱 𝗧𝗶𝗽𝘀: Essential steps to set up tools and resources for coding.

𝗕𝗮𝘀𝗶𝗰 𝗚𝗶𝘁 𝗖𝗼𝗺𝗺𝗮𝗻𝗱𝘀: A handy list of Git commands to manage your projects.

𝗟𝗲𝗮𝗿𝗻𝗶𝗻𝗴 𝗣𝗮𝘁𝗵: A structured timeline for mastering HTML, CSS, JavaScript, and more.

𝗖𝗮𝗿𝗲𝗲𝗿 𝗣𝗮𝘁𝗵𝘀 & 𝗥𝗲𝗾𝘂𝗶𝗿𝗲𝗱 𝗦𝗸𝗶𝗹𝗹𝘀: Insight into the skills needed for various tech roles.

𝗕𝗲𝗴𝗶𝗻𝗻𝗲𝗿 𝗣𝗿𝗼𝗷𝗲𝗰𝘁𝘀: Ideas to apply your skills and build your portfolio.

𝗔𝗱𝘃𝗮𝗻𝗰𝗲𝗱 𝗟𝗲𝗮𝗿𝗻𝗶𝗻𝗴 𝗥𝗲𝘀𝗼𝘂𝗿𝗰𝗲𝘀: Links to platforms for further learning.

𝗗𝗲𝘃𝗲𝗹𝗼𝗽𝗲𝗿 𝗦𝗮𝗹𝗮𝗿𝘆 𝗚𝘂𝗶𝗱𝗲: Estimated salaries for different experience levels in the US and India."/>
                <Preview animationDelay={0.3} imageURL={require("../assets/translator.png")} title="Translator" github="https://github.com/jashvakharia/translator" description="A web application that translates text in real-time, allowing users to convert text from any language to another instantly! This project combines modern design principles with practical functionality, making it user-friendly and efficient.

- Real-Time Translation: As you type, see instant translations in your desired language!
- User-Centric Design: Built with Material You design principles for a smooth, intuitive experience.
- No API Key Required: Utilizes the googletrans library, making it accessible for everyone.
- Interactive UI: Clean interface with rounded corners and animations to enhance user engagement."/>
            </div>

            <Footer />
        </div>
    );
}

export default Projects;
