import React from 'react'

function Footer() {
  return (
    <div className='footer'>
        <span>Systems Developer</span>
        <svg
                        // width="317"
                        height="31"
                        viewBox="0 0 317 161"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="loader-svg-ol"
                    >
                        <path
                            d="M146.934 23.46H36.9536L42.9336 0.579989H171.374L128.474 161H0.0335999L18.4936 92.36H36.6936L24.4736 138.12H79.5936L97.7936 69.48H134.454L146.934 23.46ZM298.28 0.579989H316.74L273.84 161H163.6L169.84 138.12H151.64L188.3 0.579989H206.5L188.04 69.48H224.7L206.5 138.12H261.62L298.28 0.579989Z"
                            fill="white"
                        />
        </svg>
        <span>Jash Vakharia</span>
    </div>
  )
}

export default Footer