import { useGSAP } from "@gsap/react";
import React, { useRef } from "react";
import gsap from "gsap";
import {RemoveScrollBar} from 'react-remove-scroll-bar';

<RemoveScrollBar />
function PreLoader({ loadTime, delay, title }) {
    useGSAP(() => {
        gsap.fromTo(
            ".loading-page",
            { opacity: 1 },
            {
                opacity: 0,
                duration: loadTime,
                delay: delay,
                display: "none",
            }
        );

        gsap.fromTo(
            "body",
            { overflow: "hidden" },
            {
                overflow: "scroll",
                duration: loadTime,
                delay: delay,
            }
        );
    });
    return (
        <div className="loading-page">
            <svg
                width="317"
                height="161"
                viewBox="0 0 317 161"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="loader-svg"
            >
                <path
                    d="M146.934 23.46H36.9536L42.9336 0.579989H171.374L128.474 161H0.0335999L18.4936 92.36H36.6936L24.4736 138.12H79.5936L97.7936 69.48H134.454L146.934 23.46ZM298.28 0.579989H316.74L273.84 161H163.6L169.84 138.12H151.64L188.3 0.579989H206.5L188.04 69.48H224.7L206.5 138.12H261.62L298.28 0.579989Z"
                    fill="white"
                />
            </svg>
            {title != null ? <h1>{title}</h1> : null}
        </div>
    );
}

export default PreLoader;
