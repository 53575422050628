import { a } from "framer-motion/client";
import React from "react";
import { FaLink } from "react-icons/fa6";
import { delay, motion, useAnimation, useInView } from "framer-motion"; 

function Preview({ imageURL, title, description, link, github, animationDelay }) {
    return <motion.div 
    initial={{
        opacity: 0,
        y: 100,
    }}
    whileInView={{
        opacity: 1,
        y: 0,
        transition: {
            duration: 1.3,
            delay: animationDelay == null ? 0 : animationDelay
        }
    }}
    viewport={{
        // once: false,
        amount: 0.2
    }}
    className="preview">
        <img src={imageURL} alt="preview" />
        <div className="pre-content">
        <h2>{title}</h2>
        <p>
            {description}
        </p>
        <div className="links-row">
        <a href={github}>GitHub</a>
        {
            link != null ? <a href={link}><FaLink /></a> : null
        }
        </div>
        </div>
    </motion.div>;
}

export default Preview;
