import { faChevronCircleDown } from '@fortawesome/free-solid-svg-icons/faChevronCircleDown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

function DownLink({ title, to }) {
  const handleScroll = () => {
    const target = document.querySelector(`.${to}`);
    if (target) {
      target.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className='downlink' onClick={handleScroll}>
        <p>{title}</p>
        <FontAwesomeIcon color='white' icon={faChevronCircleDown}></FontAwesomeIcon>
    </div>
  );
}

export default DownLink;
