import React, { useEffect, useRef } from "react";
import FeatureDisplay from "../components/FeatureDisplay";
import DownLink from "../components/DownLink";
import { FaInstagram } from "react-icons/fa";
import { FaGithub, FaLinkedin, FaTelegram, FaTwitter, FaWhatsapp } from "react-icons/fa6";
import PreLoader from "./PreLoader";

import { delay, motion, useAnimation, useInView } from "framer-motion"; 
import { fadeIn } from "../animations/variants";
import Preview from "../components/Preview";
import Footer from "../components/Footer";
import DynamicNavigator from "../components/DynamicNavigator";
import { useLocation } from "react-router-dom";
import {RemoveScrollBar} from 'react-remove-scroll-bar';




function Home() {
    const childVariant = {
        hidden: { opacity: 0 },
        show: { opacity: 1 }
    }

    return (
    <div className="home">
        <PreLoader loadTime={1.5} delay={3}/>

        <h1>Jash Vakharia</h1>
        <p>
            Hi. I’m <span>a Backend Systems Expert</span> and I specialise in creating custom backend 
            systems for applications and Web Apps.
        </p>

        <div className="features">
            <FeatureDisplay title="PYTHON" logo={require("../assets/python.png")} description="With over 3 years of experience with python, I believe it is my strongest suite and the language that I have the most command over."/>
            <FeatureDisplay title="REACT.JS" logo={require("../assets/react.png")} description="I’ve been working with React for almost
                                    2 years now and have worked on multiple
                                    real-scale projects alongside frameworks such as Tailwind and RouterDOM."/>
            <FeatureDisplay title="GO LANG" logo={require("../assets/golang.png")} description="One of the newest languages on my resume, and one of the most unique as well, GO has been a good change from my everyday languages."/>
        </div>

        <div className="spacer">
    <DownLink title="Explore " to="projects" />
</div>


        <section className="about">
            <div className="ab-left">
                <h1>ABOUT ME</h1>
                <motion.div
                    initial={{
                        opacity: 0,
                        y: 100,
                    }}
                    whileInView={{
                        opacity: 1,
                        y: 0,
                        transition: {
                            duration: 1.3
                        }
                    }}
                    viewport={{
                        // once: false,
                        amount: 0.2
                    }}
                >
                    <img src={require("../assets/vic.jpg")} />
                </motion.div>
            </div>

            <div className="ab-right">
            <p>
                Hi, I'm Jash Vakharia, a backend systems expert with a passion for building scalable, efficient, and user-centric applications. I specialize in creating robust APIs, crafting dynamic real-time applications, and integrating AI-powered tools to solve real-world problems. With a focus on efficiency and elegant design, I've worked on diverse projects like cryptocurrency trackers, multiplayer games, online code runners, and weather irrigation predictors, often leveraging Material You design principles for an accessible and modern aesthetic. I'm driven by the challenge of blending cutting-edge technology with everyday usability and aspire to contribute meaningfully to the tech community through innovative, impactful solutions.
            </p>
            <div className="linkgrid">
                <a 
                href="https://www.instagram.com/_jashhh.v/" className="coolink">
                    <FaInstagram size={22} className="linkcon" color="pink"/>
                </a>
                <a 
                href="https://www.linkedin.com/in/jashvakharia/" className="coolink">
                    <FaLinkedin size={22} className="linkcon" color="teal"/>
                </a>
                <a 
                href="https://www.github.com/jashvakharia" className="coolink">
                    <FaGithub size={22} className="linkcon" color="navy"/>
                </a>
                <a 
                href="https://api.whatsapp.com/send?phone=919324478390" className="coolink">
                    <FaWhatsapp size={22} className="linkcon" color="green"/>
                </a>
            </div>
            <a href="mailto:jashvakharia@gmail.com" className="email">Email Me</a>
            </div>

            
        </section>

        <section className="projects">
            <div className="pro-header">
                <h1>PROJECTS</h1>
                <a className="sender" href="/projects">View All</a>
            </div>
            <div className="preview-list">
                <Preview animationDelay={0.1} imageURL={require("../assets/saavn.png")} title="JioSaavn Player" github="https://github.com/jashvakharia/saavn-web-ui" link="https://mpstme-song-player-jash.onrender.com/" description="It's an all-in-one ad-free online music player where you can: Stream your favorite songs without interruptions Download tracks for free with just a click 🎧 Enjoy a seamless and clean interface I created this platform to make music accessible to everyone, without the clutter of ads or subscriptions. If you're looking for a smooth and hassle-free music experience, give it a try!"/>
                <Preview animationDelay={0.2} imageURL={require("../assets/beginner.png")} title="Beginner Course" github="https://github.com/jashvakharia/beginner-course" link="https://jashvakharia.in/beginner/" description="I created a beginner-friendly resource designed to help aspiring developers embark on their programming journey. 🌟

𝘞𝘩𝘢𝘵’𝘴 𝘐𝘯𝘴𝘪𝘥𝘦?

𝗚𝗲𝘁𝘁𝗶𝗻𝗴 𝗦𝘁𝗮𝗿𝘁𝗲𝗱 𝗧𝗶𝗽𝘀: Essential steps to set up tools and resources for coding.

𝗕𝗮𝘀𝗶𝗰 𝗚𝗶𝘁 𝗖𝗼𝗺𝗺𝗮𝗻𝗱𝘀: A handy list of Git commands to manage your projects.

𝗟𝗲𝗮𝗿𝗻𝗶𝗻𝗴 𝗣𝗮𝘁𝗵: A structured timeline for mastering HTML, CSS, JavaScript, and more.

𝗖𝗮𝗿𝗲𝗲𝗿 𝗣𝗮𝘁𝗵𝘀 & 𝗥𝗲𝗾𝘂𝗶𝗿𝗲𝗱 𝗦𝗸𝗶𝗹𝗹𝘀: Insight into the skills needed for various tech roles.

𝗕𝗲𝗴𝗶𝗻𝗻𝗲𝗿 𝗣𝗿𝗼𝗷𝗲𝗰𝘁𝘀: Ideas to apply your skills and build your portfolio.

𝗔𝗱𝘃𝗮𝗻𝗰𝗲𝗱 𝗟𝗲𝗮𝗿𝗻𝗶𝗻𝗴 𝗥𝗲𝘀𝗼𝘂𝗿𝗰𝗲𝘀: Links to platforms for further learning.

𝗗𝗲𝘃𝗲𝗹𝗼𝗽𝗲𝗿 𝗦𝗮𝗹𝗮𝗿𝘆 𝗚𝘂𝗶𝗱𝗲: Estimated salaries for different experience levels in the US and India."/>
                <Preview animationDelay={0.3} imageURL={require("../assets/translator.png")} title="Translator" github="https://github.com/jashvakharia/translator" description="A web application that translates text in real-time, allowing users to convert text from any language to another instantly! This project combines modern design principles with practical functionality, making it user-friendly and efficient.

- Real-Time Translation: As you type, see instant translations in your desired language!
- User-Centric Design: Built with Material You design principles for a smooth, intuitive experience.
- No API Key Required: Utilizes the googletrans library, making it accessible for everyone.
- Interactive UI: Clean interface with rounded corners and animations to enhance user engagement."/>
            </div>
        </section>

        <section className="contact">
            <div class="talk">
                <h1>GET IN TOUCH</h1>
                <span>Willing to start something new? Email to the address below.</span>
                <a>jashvakharia@gmail.com</a>
            </div>
        </section>

        <Footer />
    </div>
    );
}

export default Home;
