import React from "react";
import PreLoader from "./PreLoader";
import {RemoveScrollBar} from 'react-remove-scroll-bar';



function Contact() {
    
    return (
        
        <div className="contact-main">
            <RemoveScrollBar />
            <PreLoader loadTime={0.6} delay={1.2} title="CONTACT" />
            <h2>Contact Me</h2>
            <div class="talk">
                <a className="main-a" href="mailto:jashvakharia@gmail.com">jashvakharia@gmail.com</a>
                <span>I will try my best to respond as soon as possible.</span>
            </div>

            <input type="text" placeholder="Name" className="in-name inp"/>
            <input type="text" placeholder="Email Address" className="in-email inp"/>
            <textarea type="text" placeholder="Message" className="in-message inp"/>

            <button>Submit</button>
        </div>
    );
}

export default Contact;
