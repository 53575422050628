import { faGripLines, faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { FaBars, FaCross } from "react-icons/fa6";
import { FaTimes } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { use } from "framer-motion/client";

function DynamicNavigator() {
    const routerState = window.location.pathname;
    const [hyperNavigator, setHyperNavigator] = useState(0);
    const [overlayState, setOverlayState] = useState(0);

    useEffect(() => {
        if (window.location.pathname == "/projects") {
            setHyperNavigator(1);
        } else if (window.location.pathname == "/contact") {
            setHyperNavigator(2);
        } else {
            document.addEventListener("scroll", (e) => {
                if (window.scrollY > 700 && window.scrollY < 2000) {
                    setHyperNavigator(1);
                } else if (window.scrollY < 1000) {
                    setHyperNavigator(0);
                } else {
                    setHyperNavigator(2);
                }
            });
        }
    });

    return (
        <div className="dynav-parent">
            <div className="dynav">
                <a href="/">
                    <h2>JV</h2>
                </a>
                <div className="links">
                    <a
                        href="/"
                        className={
                            hyperNavigator == 0 ? "linker actilink" : "linker"
                        }
                    >
                        Home
                    </a>
                    <a
                        href="/projects"
                        className={
                            hyperNavigator == 1 ? "linker actilink" : "linker"
                        }
                    >
                        Portfolio
                    </a>
                    <a
                        href="/contact"
                        className={
                            hyperNavigator == 2 ? "linker actilink" : "linker"
                        }
                    >
                        Contact
                    </a>
                </div>

                <button className="colorink" onClick={() => setOverlayState(1)}>
                    {/* <FontAwesomeIcon icon={faLink}></FontAwesomeIcon> */}
                    <FaBars />
                </button>

                <div className="burger">
                    <FontAwesomeIcon
                        className="bur-con"
                        icon={faGripLines}
                    ></FontAwesomeIcon>
                </div>
            </div>
            <div
                className={
                    overlayState == 1 ? "overlay" : "overlay overlay-fail"
                }
            >
                <button className="close" onClick={() => setOverlayState(0)}>
                    <FaTimes />
                </button>

                <div className="ol-content">
                    <svg
                        width="317"
                        height="41"
                        viewBox="0 0 317 161"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="loader-svg-ol"
                    >
                        <path
                            d="M146.934 23.46H36.9536L42.9336 0.579989H171.374L128.474 161H0.0335999L18.4936 92.36H36.6936L24.4736 138.12H79.5936L97.7936 69.48H134.454L146.934 23.46ZM298.28 0.579989H316.74L273.84 161H163.6L169.84 138.12H151.64L188.3 0.579989H206.5L188.04 69.48H224.7L206.5 138.12H261.62L298.28 0.579989Z"
                            fill="white"
                        />
                    </svg>
                    <div className="ol-links">
                        <a href="/">Home</a>
                        <a href="/projects">Projects</a>
                        <a href="/contact">Contact</a>
                        <a href="https://www.github.com/jashvakharia">GitHub</a>
                    </div>
                    <span>Designed by Jash Vakharia</span>
                </div>
            </div>
            ;
        </div>
    );
}

export default DynamicNavigator;
